import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PageDescriptionContainer } from "../practitioner/practitionerstyles"
import { PosterImageContainer } from "../commons/PosterContainer.style"
import { ImageFadeIn } from "../commons/HighlightsCards.style"

import {
  MovementContainer,
  StudiesCaptionOP,
  ExtLink,
  PosterTitle,
  DownloadLink,
  PolygonCenterDown,
  PosterSubTitle,
  TopContainer,
  InnerContainer,
  LogoContainer,
} from "../movements/styled"

import B2Bpdf from "../../resources/brochures/b2b.pdf"
import facsiaNatyapdf from "../../resources/brochures/fascianatya.pdf"
import generalPdf from "../../resources/brochures/generalmovement.pdf"
import coreconceptsPdf from "../../resources/brochures/coreconcepts.pdf"

const rightArrow = require("../../images/right.svg").default
const leftArrow = require("../../images/arrow.svg").default

interface MovementStory {
  id: number
  title: string
  alt_text: string
  image: string
  description: { index: number; para: string }[]
}

const MovementStudy = () => {
  const [index, setIndex] = React.useState(0)

  const movementStories: MovementStory[] = [
    {
      id: 0,
      title: "Bharatha-to-Bartenieff",
      image: "../../images/Movement/coreconcepts.png",
      alt_text: "Movement Studies",
      description: [
        {
          index: 0,
          para: "Bharatha-to-Bartenieff” is a unique research based trans-cultural, trans-disciplinary movement studies programme that emerged as an organic integration of two major movement systems in Rajyashree’s work – the vocabulary and aesthetics in Indian corporeal traditions and Laban Movement Analysis in conjunction with Bartenieff Fundamentals, in short LMA/BF. Supported by academic research, this ground-breaking programme further encompasses insights from several fields ranging from Linguistic Gesture Studies to Brain Research on Emotions, from Movement Studies to current Fascia Research, thus making it a one of its kind programme in the world of corporeal disciplines and traditions. Most importantly, it develops a broad-based understanding of the body and its movement principles from anatomical, fascial and emotivekinetic- cognitive perspectives, thus placing the study and practise of the Indian traditions on a new plane.",
        },
        {
          index: 1,
          para: "Currently being offered as modular Diploma programme with two central modules: “FasciaNatya” and “CoreConcepts",
        },
      ],
    },
    {
      id: 1,
      title: "FasciaNatya",
      alt_text: "programming highlights",
      image: "../../images/Movement/programming_highlights.svg",
      description: [
        {
          index: 0,
          para: "FasciaNatya is a first-of-its-kind methodology developed and designed by Dr. Rajyashree Ramesh that delineates the vocabulary of Nātya both in relation to the broad spectrum of movement principles defined in Laban/Bartenieff Movement Studies system and insights from latest Fascia Research (particularly fascial structures and behaviour as a connective tissue). The programme is aimed to give participants tools that enable them to apply dance knowledge from new perspectives and to varied fields, integrating performative aesthetics, dance/movement pedagogy and dance/movement therapy.",
        },
      ],
    },
    {
      id: 2,
      title: "coreconcepts",
      image: "../../images/Movement/coreconcepts.svg",
      alt_text: "core concepts",
      description: [
        {
          index: 0,
          para: "CoreConcepts is a methodology Rajyashree Ramesh developed during her doctoral research, where she places the relevance given to emotive meaning in terms of bhāvā and rāsā in the Indian performing arts context in the theoretical framework of brain research of emotions. The approach centres around exploring emotive expressivity as emergent from the micro-levels of the body where emotions are located as movement, relating the subtle movement qualities of such primary emergent movement in emotive-kinetic processes to the refined gestures in Nātya. What may be the narratives that ensue when concepts arise as the outcome of such explorations? The methodology offers new perspective on performative aesthetics for application in varied fields including therapy.",
        },
      ],
    },
    {
      id: 3,
      title: "Moving on with Movement",
      image: "../../images/Movement/whoisitfor.svg",
      alt_text: "who is it for",
      description: [
        {
          index: 0,
          para: "Movement is primordial for how we experience and act in our physical, mental, emotional, and cognitive realms. At the same time our movement signature is also influenced by these. Even if we do not pay attention to our movement, mostly over-emphasising mind-related activities, it continues to be the primary means by way of which we learn to address naturally and effectively a wide range of factors, from focus, attention, aliveness and dynamism to cognitive skills, creativity and communication.",
        },
        {
          index: 1,
          para: "While movement and its importance is often discussed today, it is not just exercise-regimes, building muscles or being athletic, but how we move integrating the structural, functional and also expressive aspects that makes a significant difference. And finally, what does it mean to move with inner awareness?",
        },
        {
          index: 2,
          para: "So, differentiating the purpose of movement - for whom, when and where - is central to something so central! And the goal behind the 'Moving on with Movement' programmes",
        },
      ],
    },
  ]

  const handlePrev = () => {
    if (index > 0) {
      setIndex(index - 1)
    }
  }

  const handleNext = () => {
    if (index < 3) {
      setIndex(index + 1)
    }
  }

  return (
    <MovementContainer>
      <TopContainer>
        <LogoContainer onClick={handlePrev}>
          <div style={{ visibility: index === 0 ? "hidden" : "visible" }}>
            {index > 0 && <img src={leftArrow} alt="left Arrow" />}
          </div>
        </LogoContainer>
        <InnerContainer>
          <PosterTitle>MOVEMENT STUDIES</PosterTitle>
          <PosterSubTitle>{movementStories[index].title}</PosterSubTitle>
        </InnerContainer>
        <LogoContainer onClick={handleNext}>
          <div style={{ visibility: index == 3 ? "hidden" : "visible" }}>
            {index < 3 && <img src={rightArrow} alt="right Arrow" />}
          </div>
        </LogoContainer>
      </TopContainer>

      <div style={{ display: index === 0 ? "block" : "none" }}>
        <PageDescriptionContainer>
          <PosterImageContainer width="1020px">
            <ImageFadeIn>
              <StaticImage
                src="../../images/Movement/studiesmobile.svg"
                alt="movement studies"
                placeholder="dominantColor"
                as="div"
              />
            </ImageFadeIn>
          </PosterImageContainer>
        </PageDescriptionContainer>
        <StudiesCaptionOP>
          {movementStories[index].description[0] && (
            <p>{movementStories[index].description[0].para}</p>
          )}
          {movementStories[index].description[1] && (
            <p>
              <ExtLink
                href="https://global-music-school.net/en/global-dance-professional-2/"
                target="_blank"
              >
                {movementStories[index].description[1].para}
              </ExtLink>
            </p>
          )}

          <DownloadLink
            href={B2Bpdf}
            download="Bharatha_to_Bartenieff_Brochure.pdf"
          >
            DOWNLOAD BROCHURE
          </DownloadLink>

          <DownloadLink
            href="https://vimeo.com/608846980/0967485a93"
            target={"_blank"}
          >
            WATCH VIDEO
          </DownloadLink>
        </StudiesCaptionOP>
      </div>

      <div style={{ display: index === 1 ? "block" : "none" }}>
        <PageDescriptionContainer>
          <PosterImageContainer width="1020px">
            <ImageFadeIn>
              <StaticImage
                src="../../images/Movement/fascinatya.png"
                alt="programming highlights"
                placeholder="dominantColor"
                as="div"
              />
            </ImageFadeIn>
          </PosterImageContainer>
        </PageDescriptionContainer>
        <StudiesCaptionOP>
          {movementStories[index].description[0] && (
            <p>{movementStories[index].description[0].para}</p>
          )}

          <DownloadLink
            href={facsiaNatyapdf}
            download="FasciaNatya_Brochure.pdf"
          >
            DOWNLOAD BROCHURE
          </DownloadLink>
        </StudiesCaptionOP>
      </div>

      <div style={{ display: index === 2 ? "block" : "none" }}>
        <PageDescriptionContainer>
          <PosterImageContainer width="1020px">
            <ImageFadeIn>
              <StaticImage
                src="../../images/Movement/coreconcepts.png"
                alt="core concepts"
                placeholder="dominantColor"
                as="div"
              />
            </ImageFadeIn>
          </PosterImageContainer>
        </PageDescriptionContainer>
        <StudiesCaptionOP>
          {movementStories[index].description[0] && (
            <p>{movementStories[index].description[0].para}</p>
          )}

          <DownloadLink
            href={coreconceptsPdf}
            download="CoreConcepts_Brochure.pdf"
          >
            DOWNLOAD BROCHURE
          </DownloadLink>

          <DownloadLink
            href="https://vimeo.com/608846980/0967485a93"
            target={"_blank"}
          >
            WATCH VIDEO
          </DownloadLink>
        </StudiesCaptionOP>
      </div>

      <div style={{ display: index === 3 ? "block" : "none" }}>
        <PageDescriptionContainer>
          <PosterImageContainer width="1020px">
            <ImageFadeIn>
              <StaticImage
                src="../../images/Movement/whoisitfor.png"
                alt="who is it for"
                placeholder="dominantColor"
                as="div"
              />
            </ImageFadeIn>
          </PosterImageContainer>
        </PageDescriptionContainer>
        <StudiesCaptionOP>
          {movementStories[index].description[0] && (
            <p>{movementStories[index].description[0].para}</p>
          )}
          {movementStories[index].description[1] && (
            <p>{movementStories[index].description[1].para}</p>
          )}
          {movementStories[index].description[2] && (
            <p>{movementStories[index].description[2].para}</p>
          )}

          <DownloadLink
            href={generalPdf}
            download="General_Movement_Brochure.pdf"
          >
            DOWNLOAD BROCHURE
          </DownloadLink>
        </StudiesCaptionOP>
      </div>

      <PolygonCenterDown>
        <StaticImage
          src="../../images/Movement/PolygonCenter.png"
          alt="ploygon center"
          placeholder="dominantColor"
        />
      </PolygonCenterDown>
    </MovementContainer>
  )
}

export default MovementStudy
