import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Movement from "../components/movementStudies";
import MovementStudy from "../components/movements";

const logoOfMobile = require("../images/whitelogo.svg").default;
const logoOfDesktop = require("../images/logo.svg").default;

const MovementStudiesPage = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize(); // Call it initially
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <Layout
          backgroundColor="#253140"
          logo={logoOfMobile}
          menuBGColor="black"
          hamburgerColor="white"
          footerColor="#253140"
        >
          <Seo title="Rajyashree Ramesh" />
          <MovementStudy />
        </Layout>
      ) : (
        <Layout
          backgroundColor="white"
          logo={logoOfDesktop}
          menuBGColor="black"
          hamburgerColor="black"
          footerColor="rgb(21, 34, 35)"
        >
          <Seo title="Rajyashree Ramesh" />
          <Movement />
        </Layout>
      )}
    </>
  );
};

export default MovementStudiesPage;
  