import styled from "@emotion/styled"
import { Link } from "gatsby"

export const StudiesCaption = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgb(21, 34, 35);
  padding: 5%;

  & > h1 {
    width: 100%;
    height: 34px;
    font-family: Marcellus;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
    margin-top: 3%;
  }

  & > p {
    width: 1024px;
    font-family: Marcellus;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
    margin-top: 2%;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  & > h6 {
    width: 100%;
    height: 24px;
    font-family: Marcellus;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #8f8f8f;
    text-align: center;
  }
`

export const PolygonLeft = styled("div")`
  position: absolute;
  height: 200px;
  left: 0px;
  top: 700px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    display: none;
  }
`

export const PolygonRight = styled("div")`
  position: absolute;
  height: 200px;
  right: 0px;
  top: 1490px;
  box-sizing: border-box;
  transform: matrix(-1, 0, 0, 1, 0, 0);

  @media (max-width: 768px) {
    display: none;
  }
`

export const PolygonCenterDown = styled("div")`
  position: absolute;
  left: 45%;
  margin-top: -5%;
  img {
    height: auto;
    max-width: 200px;

    @media (max-width: 768px) {
      display: none;
    }
  }
`

type WrapperProps = {
  direction: string
}

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 6%;
  display: flex;
  flex-direction: ${(props: WrapperProps) => props.direction};
  justify-content: space-around;
  align-items: center;
  background: rgb(21, 34, 35);
`

export const MovementWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 5%;
  flex-direction: ${(props: WrapperProps) => props.direction};
  justify-content: space-around;
  align-items: center;
  background: rgb(21, 34, 35);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const InfoCard = styled.div`
  img {
    max-width: 500px;
    height: 100%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`

export const DescWrapper = styled.div`
  width: 50%;
  margin: 5%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (max-width: 768px) {
    width: 100%;
  }
`
export const Title = styled.div`
  font-family: Marcellus;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 34px;
  margin-bottom: 5%;

  color: #ffffff;
`

export const Desc = styled.p`
  font-family: Marcellus;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 28px;
  }
`
export const LinkWrapper = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`
export const DownloadLink = styled.a`
  font-family: Marcellus;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  color: #8f8f8f;
  margin-right:12px;
`

export const Container = styled.div`
  /* @media (max-width: 768px) {
    display: none;
  } */
`

export const ExtLink = styled("a")`
  margin-right: auto;
  color: white;
  text-decoration: underline;
`
