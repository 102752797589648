import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  StudiesCaption,
  PolygonLeft,
  PolygonRight,
  PolygonCenterDown,
  InfoCard,
  DescWrapper,
  Title,
  Desc,
  DownloadLink,
  LinkWrapper,
  MovementWrapper,
  Container,
  ExtLink,
} from "./studiesStyles"
import {
  PosterImageContainer,
  PosterTitle,
} from "../commons/PosterContainer.style"
import { ImageFadeIn } from "../commons/HighlightsCards.style"
import { PageDescriptionContainer } from "../practitioner/practitionerstyles"
import B2Bpdf from "../../resources/brochures/b2b.pdf"
import facsiaNatyapdf from "../../resources/brochures/fascianatya.pdf"
import generalPdf from "../../resources/brochures/generalmovement.pdf"
import coreconceptsPdf from "../../resources/brochures/coreconcepts.pdf"

const Movement = () => (
  <>
    <Container>
      <PosterTitle color="#00000">MOVEMENT STUDIES</PosterTitle>
      <PageDescriptionContainer backgroundColor="rgb(21, 34, 35)">
        <PosterImageContainer width="1020px">
          <ImageFadeIn>
            <StaticImage
              src="../../images/Movement/studies_1.svg"
              alt="movement studies"
              placeholder="dominantColor"
            />
          </ImageFadeIn>
        </PosterImageContainer>
      </PageDescriptionContainer>
      <StudiesCaption>
        <p>
          “Bharatha-to-Bartenieff” is a unique research based trans-cultural,
          trans-disciplinary movement studies programme that emerged as an
          organic integration of two major movement systems in Rajyashree’s work
          – the vocabulary and aesthetics in Indian corporeal traditions and
          Laban Movement Analysis in conjunction with Bartenieff Fundamentals,
          in short LMA/BF. Supported by academic research, this ground-breaking
          programme further encompasses insights from several fields ranging
          from Linguistic Gesture Studies to Brain Research on Emotions, from
          Movement Studies to current Fascia Research, thus making it a one of
          its kind programme in the world of corporeal disciplines and
          traditions. Most importantly, it develops a broad-based understanding
          of the body and its movement principles from anatomical, fascial and
          emotivekinetic- cognitive perspectives, thus placing the study and
          practise of the Indian traditions on a new plane.
        </p>
        <p>
          <ExtLink
            href="https://global-music-school.net/en/global-dance-professional-2/"
            target="_blank"
          >
            Currently being offered as modular Diploma programme with two
            central modules: "FasciaNatya" and "CoreConcepts".
          </ExtLink>
        </p>
        <LinkWrapper>
          <DownloadLink
            href={B2Bpdf}
            target="_blank"
            download="Bharatha_to_Bartenieff_Brochure.pdf"
          >
            DOWNLOAD BROCHURE
          </DownloadLink>
          <DownloadLink>|</DownloadLink>
          <DownloadLink
            href="https://vimeo.com/608846980/0967485a93"
            target={"_blank"}
          >
            WATCH VIDEO
          </DownloadLink>
        </LinkWrapper>
      </StudiesCaption>
      <>
        <PolygonLeft>
          <StaticImage
            src="../../images/Movement/polygonLeft.png"
            alt="polygon left"
            placeholder="dominantColor"
          />
        </PolygonLeft>
        <PolygonRight>
          <StaticImage
            src="../../images/Movement/polygonLeft.png"
            alt="polygon right"
            placeholder="dominantColor"
          />
        </PolygonRight>

        <MovementWrapper direction="row">
          <InfoCard>
            <StaticImage
              src="../../images/Movement/programming_highlights.svg"
              alt="programming highlights"
              placeholder="dominantColor"
            />
          </InfoCard>

          <DescWrapper>
            <Title>FASCIANATYA</Title>
            <Desc>
              FasciaNatya is a first-of-its-kind methodology developed and
              designed by Dr. Rajyashree Ramesh that delineates the vocabulary
              of Nātya both in relation to the broad spectrum of movement
              principles defined in Laban/Bartenieff Movement Studies system and
              insights from latest Fascia Research (particularly fascial
              structures and behaviour as a connective tissue). The programme is
              aimed to give participants tools that enable them to apply dance
              knowledge from new perspectives and to varied fields, integrating
              performative aesthetics, dance/movement pedagogy and
              dance/movement therapy.
            </Desc>
            <DownloadLink
              href={facsiaNatyapdf}
              download="FasciaNatya_Brochure.pdf"
            >
              DOWNLOAD BROCHURE
            </DownloadLink>
          </DescWrapper>
        </MovementWrapper>
        <PolygonCenterDown>
          <StaticImage
            src="../../images/Movement/PolygonCenter.png"
            alt="ploygon center"
            placeholder="dominantColor"
          />
        </PolygonCenterDown>

        <MovementWrapper direction="row-reverse">
          <InfoCard>
            <StaticImage
              src="../../images/Movement/coreconcepts.svg"
              alt="core concepts"
              placeholder="dominantColor"
            />
          </InfoCard>
          <DescWrapper>
            <Title>CORECONCEPTS</Title>
            <Desc>
              CoreConcepts is a methodology Rajyashree Ramesh developed during
              her doctoral research, where she places the relevance given to
              emotive meaning in terms of bhāvā and rāsā in the Indian
              performing arts context in the theoretical framework of brain
              research of emotions. The approach centres around exploring
              emotive expressivity as emergent from the micro-levels of the body
              where emotions are located as movement, relating the subtle
              movement qualities of such primary emergent movement in
              emotive-kinetic processes to the refined gestures in Nātya. What
              may be the narratives that ensue when concepts arise as the
              outcome of such explorations? The methodology offers new
              perspective on performative aesthetics for application in varied
              fields including therapy.
            </Desc>
            <LinkWrapper>
              <DownloadLink
                href={coreconceptsPdf}
                download="CoreConcepts_Brochure.pdf"
              >
                DOWNLOAD BROCHURE
              </DownloadLink>
              <DownloadLink>|</DownloadLink>
              <DownloadLink
                href="https://youtu.be/R3-SQu2ZMDM"
                target={"_blank"}
              >
                WATCH VIDEO
              </DownloadLink>
            </LinkWrapper>
          </DescWrapper>
        </MovementWrapper>

        <MovementWrapper direction="row">
          <InfoCard>
            <StaticImage
              src="../../images/Movement/whoisitfor.svg"
              alt="who is it for"
              placeholder="dominantColor"
            />
          </InfoCard>
          <DescWrapper>
            <Title>Moving on with Movement</Title>
            <Desc>
              Movement is primordial for how we experience and act in our
              physical, mental, emotional, and cognitive realms. At the same
              time our movement signature is also influenced by these. Even if
              we do not pay attention to our movement, mostly over-emphasising
              mind-related activities, it continues to be the primary means by
              way of which we learn to address naturally and effectively a wide
              range of factors, from focus, attention, aliveness and dynamism to
              cognitive skills, creativity and communication. While movement and
              its importance is often discussed today, it is not just
              exercise-regimes, building muscles or being athletic, but how we
              move integrating the structural, functional and also expressive
              aspects that makes a significant difference. And finally, what
              does it mean to move with inner awareness? So, differentiating the
              purpose of movement - for whom, when and where - is central to
              something so central! And the goal behind the “Moving on with
              Movement” programmes.
            </Desc>
            <DownloadLink
              href={generalPdf}
              download="General_Movement_Brochure.pdf"
            >
              DOWNLOAD BROCHURE
            </DownloadLink>
          </DescWrapper>
        </MovementWrapper>
      </>
    </Container>
  </>
)

export default Movement
