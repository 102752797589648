import styled from "@emotion/styled"

export const TopContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom:20px;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const MovementContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #253140;
  height: auto;
  @media (min-width: 768px) {
    display: none;
  }
`

export const PosterTitle = styled.div`
  font-family: Marcellus;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: ${({ color }) => (color ? color : "#ffffff")};
  padding-top: 30px;
  padding-bottom: 20px;
`

export const PosterSubTitle = styled.div`
  color: #fff;
  font-family: Marcellus;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
`
export const StudiesCaptionOP = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #253140;
  flex-wrap: wrap;
  padding: 5%;

  & > p {
    width: 100%;
    color: #fff;
    text-align: center;
    font-family: Marcellus;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
`

export const ExtLink = styled("a")`
  margin-right: auto;
  color: white;
  text-decoration: none;
`
export const DownloadLink = styled.a`
  font-family: Marcellus;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  color: #8f8f8f;
  margin-bottom: 20px;
`
export const PolygonCenterDown = styled("div")`
  height: auto;
  max-width: 200px;
  margin: auto;
  margin-top: 40px;
`

export const LogoContainer = styled("div")`
  cursor: pointer;
`
